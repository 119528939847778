import {
  Card,
  Col,
  Container,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";

import { Image, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import reportSvc from "../services/reportSvc";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { Button } from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import { getLoginData } from "../services/authSvc";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";

const DetailReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [report, setReport] = useState({});
  moment.tz.setDefault("Asia/Bangkok");
  const history = useHistory();
  const { user } = getLoginData();
  const fetchDetailReport = async () => {
    setIsLoading(true);
    try {
      const resp = await reportSvc.getReportById(id);
      if (resp.status === 200) {
        setReport(resp.data.data);
      }
    } catch (error) {
      showErrorAlert("Gagal", "Gagal mengambil data report");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getDetail = async () => {
      await fetchDetailReport();
    };

    getDetail();
  }, []);

  const handleImageError = (type, index) => {
    if (type === "temuan") {
      console.log("masuk temuan", index);
      setReport((prevReport) => {
        console.log("Previous Report:", prevReport); // Logging the previous report state
        return {
          ...prevReport,
          temuan: {
            ...prevReport.temuan,
            [index]: "error",
          },
        };
      });
      console.log({ report });
    } else if (type === "dokumentasi") {
      setReport((prevReport) => ({
        ...prevReport,
        dokumentasis: prevReport.dokumentasis.map((dokumentasi, i) => {
          if (i === index) {
            return {
              ...dokumentasi,
              dokumentasi_url: "error",
            };
          }
          return dokumentasi;
        }),
      }));
    }
  };

  const handleDelete = async (id) => {
    const willDelete = await Swal.fire({
      title: "Konfirmasi Hapus Laporan",
      text: "Tindakan ini akan menghapus permanen laporan.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batalkan",
    });

    if (willDelete.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Menghapus data laporan...");
        const res = await reportSvc.deleteReportById(id);
        if (res.status === 200) {
          // showSuccessAlert("Berhasil", "Data laporan berhasil dihapus")
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data laporan berhasil dihapus",
          }).then(() => {
            history.goBack();
          });
        } else {
          throw new Error("Gagal menghapus laporan");
        }
      } catch (error) {
        let message =
          error.response?.data?.message || "Gagal menghapus data laporan";
        showErrorAlert("Gagal", message);
      }
    }
  };

  useEffect(() => {
    // Function to fetch report data
    const fetchReport = async () => {
      console.log({ report });
    };

    fetchReport();

    const intervalId = setInterval(fetchReport, 10000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Detail Laporan</h5>
        {user.role === "admin" ? (
          <Button
            variant="danger"
            onClick={(e) => {
              e.preventDefault();
              handleDelete(id);
            }}
          >
            Delete
          </Button>
        ) : (
          ""
        )}
      </Card.Header>
      <Card.Body>
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : report.asset_name ? (
          <Container>
            <Row>
              <Col>
                <div className="mb-4">
                  <h5>{report.asset_name}</h5>
                  <p>{report.asset_kode}</p>
                  <div className="mb-1">
                    <span className="fw-bold">Kantor Cabang</span>
                    <br />
                    <p className="h6"> {report.pelanggan_name}</p>
                  </div>
                  <div className="pr3">
                    <span className="fw-bold">Alamat</span>
                    <br />
                    <p className="h6">{report.asset_alamat}</p>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <h5>Detail Operator</h5>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="mb-2"
                  >
                    {report.user_photo_url ? (
                      <Image
                        src={`${report.user_photo_url}`}
                        alt="profile-pict"
                        width="40px"
                        height="40px"
                        style={{ borderRadius: "50%", marginRight: "10px" }}
                      />
                    ) : (
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: "orange",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                        }}
                      >
                        <span
                          style={{
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {report.user_name ? report.user_name.charAt(0) : ""}
                        </span>
                      </div>
                    )}
                    <div>
                      <span className="fw-bold">{report.user_name}</span>
                    </div>
                  </div>
                  <div>
                    <span className="fw-bold">Tanggal</span>
                    <br />
                    <p className="h6">{report.date}</p>
                  </div>
                  <div>
                    <span className="fw-bold">Waktu Selesai</span>
                    <br />
                    <p className="h6">
                      {moment
                        .utc(report.date_done)
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <div className="mb-4">
                  <h5 className="mb-2">Dokumentasi</h5>
                  <Row>
                    {report.dokumentasis
                      ? report.dokumentasis.map((image, index) => (
                          <Col key={image.id} xs={6} md={3}>
                            <div className="mb-4 d-flex flex-column">
                              <span className="mb-1">
                                {`${index + 1}.`}{" "}
                                {image.nama_jenis_dokumentasi_snapshot}
                              </span>
                              {report.dokumentasis[index]
                                .nama_jenis_dokumentasi_snapshot ? (
                                <Image
                                  src={image.dokumentasi_url}
                                  alt={`Image ${index + 1}`}
                                  className="mr-2 mb-2 rounded"
                                />
                              ) : (
                                <Skeleton.Image />
                              )}
                            </div>
                          </Col>
                        ))
                      : ""}
                  </Row>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <div>
                  <h5>Temuan</h5>
                  <div className="d-flex align-items-center mb-3">
                    <span className="fw-bold mr-2">{`Keterangan :`}</span>
                    <br />
                    <p className="h6 mb-0">
                      {report.temuan
                        ? ` ${report.temuan.keterangan}`
                        : " Tidak ada temuan"}
                    </p>
                  </div>
                  {report.temuan ? (
                    <Row>
                      {report.temuan.temuan1_url && (
                        <Col xs={6} md={3}>
                          <div className="mb-4 d-flex flex-column">
                            <span className="mb-1">1. Temuan 1</span>
                            {report.temuan.temuan1_url !== "error" ? (
                              <Image
                                src={report.temuan.temuan1_url}
                                alt="temuan1"
                                className="mr-2 mb-2 rounded"
                                onError={() => {
                                  handleImageError("temuan", "temuan1_url");
                                }}
                              />
                            ) : (
                              <Skeleton.Image />
                            )}
                          </div>
                        </Col>
                      )}

                      {report.temuan.temuan2_url && (
                        <Col xs={6} md={3}>
                          <div className="mb-4 d-flex flex-column">
                            <span className="mb-1">2. Temuan 2</span>
                            {report.temuan.temuan2_url !== "error" ? (
                              <Image
                                src={report.temuan.temuan2_url}
                                alt="temuan2"
                                className="mr-2 mb-2 rounded"
                                onError={() => {
                                  handleImageError("temuan", "temuan2_url");
                                }}
                              />
                            ) : (
                              <Skeleton.Image />
                            )}
                          </div>
                        </Col>
                      )}
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <div className="text-center">
            Failed to fetch report. Please try again later.
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default DetailReport;
