import imageCompression from "browser-image-compression";
import watermark from "watermarkjs";
import moment from "moment-timezone";
moment.tz.setDefault("Asia/Bangkok");

export const isExpired = (startDate, endDate) => {
  // Ambil bagian tanggal (tanpa waktu) dari currentDate, startDate, dan endDate
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const startDateTime = new Date(startDate).setHours(0, 0, 0, 0);
  const endDateTime = new Date(endDate).setHours(0, 0, 0, 0);

  if (endDateTime === currentDate || startDateTime > currentDate) {
    return false;
  }

  return currentDate < startDateTime || currentDate >= endDateTime;
};

export const resizeAndCompressImage = async (file) => {
  const options = {
    maxSizeMB: 0.3,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    fileType: "image/jpeg",
  };

  try {
    const compressedBlob = await imageCompression(file, options);
    const timestamp = Date.now();
    const uniqueFileName = `${timestamp}_${file.name}`;
    const compressedFile = new File([compressedBlob], uniqueFileName, {
      type: "image/jpeg",
      lastModified: timestamp,
    });
    return compressedFile;
  } catch (error) {
    throw new Error("Gagal melakukan kompresi gambar");
  }
};

export const addWatermark = async (
  srcForm,
  imageUrl,
  asset,
  jenisDoc,
  user = undefined
) => {
  const time = moment().format("YYYY-MM-DD HH:mm:ss");
  return new Promise((resolve, reject) => {
    watermark([imageUrl])
      .image((image) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        // Set font properties
        const fontSize = 30; // Font size in pixels
        const lineHeight = 1.2 * fontSize; // Line height in pixels (1.2 times font size)
        const fontFamily = "Arial"; // Font family
        const color = "#ffffff"; // Text color

        // Text content for each row
        const textRows = [
          `Operator - ${user?.name}`,
          `${srcForm} - ${jenisDoc}`,
          `${asset?.name} - ${asset?.kode}`,
          `${time}`,
        ];

        // Set the position of the text
        const startX = canvas.width - 20; // Starting X position (left margin)
        const startY = canvas.height - textRows.length * lineHeight - 5; // Starting Y position (bottom margin)
        const rowSpacing = lineHeight; // Spacing between rows

        // Draw each row of text
        ctx.font = `bold ${fontSize}px ${fontFamily}`;
        ctx.strokeStyle = "black"; // Stroke color
        ctx.lineWidth = 0.5; // Stroke width
        ctx.fillStyle = color; // Text color
        ctx.shadowColor = "rgba(0, 0, 0, 0.5)"; // Shadow color
        textRows.forEach((text, index) => {
          const x = startX - ctx.measureText(text).width; // X position (left margin)
          const y = startY + index * rowSpacing; // Y position
          ctx.fillText(text, x, y); // Fill text
          ctx.strokeText(text, x, y); // Stroke text
        });

        return canvas;
      })
      .then((img) => {
        fetch(img.src)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], "wm_image.jpg", {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            resolve(file);
          })
          .catch((error) => {
            console.error("Error fetching watermarked image:", error);
            reject(error);
          });
      })
      .catch((error) => {
        console.error("Error during watermarking:", error);
        reject(error);
      });
  });
};

export const showLoadingOverlay = () => {
  const loadingOverlay = document.getElementById("root");

  if (loadingOverlay) {
    loadingOverlay.style.display = "none";
  }
  const load = document.getElementById("loading-overlay");
  if (load) {
    load.style.display = "block";
  }
};

export const hideLoadingOverlay = () => {
  const loadingOverlay = document.getElementById("root");
  if (loadingOverlay) {
    loadingOverlay.style.display = "block";
  }
  const load = document.getElementById("loading-overlay");
  if (load) {
    load.style.display = "none";
  }
};

export const formatToRupiah = (amount) => {
  if (isNaN(amount) || amount === null) return "-";
  let amountStr = Number(amount).toFixed(0);

  let integerPart = amountStr.split(".")[0];
  let reversed = integerPart.split("").reverse().join("");
  let formatted = reversed.replace(/(\d{3})(?=\d)/g, "$1.");
  let result = formatted.split("").reverse().join("");

  return `Rp${result}`;
};
