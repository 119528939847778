import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import {
  Col,
  Row,
  Button,
  Card,
  Table,
  Form,
  InputGroup,
  Pagination,
  Spinner,
  Nav,
  FormControl,
  Image,
} from "@themesberg/react-bootstrap";
import Select from "react-select";
import reportSvc from "../services/reportSvc";
import userSvc from "../services/userSvc";
import customerSvc from "../services/customerSvc";
import assetSvc from "../services/assetSvc";
import { showErrorAlert } from "../utils/SwalUtil";
import { customStyles } from "../utils/SelectUtils";
import { getLoginData } from "../services/authSvc";
moment.tz.setDefault("Asia/Bangkok");

export default () => {
  const searchInputRef = useRef(null);
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFilter, setIsLoadingFilter] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [assets, setAssets] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [selectedTemuan, setSelectedTemuan] = useState("");
  const [totalRows, setTotalRows] = useState("");
  const { user } = getLoginData();

  const renderPaginationItems = () => {
    const paginationItems = [];

    paginationItems.push(
      <Pagination.First
        key="first"
        onClick={() => handlePageChange(1)}
        disabled={activePage === 1}
      />
    );

    paginationItems.push(
      <Pagination.Prev
        key="prev"
        onClick={() => activePage > 1 && handlePageChange(activePage - 1)}
        disabled={activePage === 1}
      />
    );

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= activePage - 2 && i <= activePage + 2)
      ) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageChange(i)}
            active={i === activePage}
          >
            {i}
          </Pagination.Item>
        );
      } else if (
        (i === activePage - 3 && i > 2) ||
        (i === activePage + 3 && i < totalPages - 1)
      ) {
        paginationItems.push(<Pagination.Ellipsis key={i} />);
      }
    }

    paginationItems.push(
      <Pagination.Next
        key="next"
        onClick={() =>
          activePage < totalPages && handlePageChange(activePage + 1)
        }
        disabled={activePage === totalPages}
      />
    );

    paginationItems.push(
      <Pagination.Last
        key="last"
        onClick={() => handlePageChange(totalPages)}
        disabled={activePage === totalPages}
      />
    );

    return paginationItems;
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setActivePage(1);
  };

  const handleStartDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setStartDate(formattedDate);
    if (moment(formattedDate).isAfter(endDate)) {
      setEndDate(formattedDate);
    }
  };

  const handleEndDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setEndDate(formattedDate);
    if (moment(formattedDate).isBefore(startDate)) {
      setStartDate(formattedDate);
    }
  };

  const fetchReports = async () => {
    try {
      setIsLoading(true);
      const params = {
        page: activePage - 1,
        limit: itemsPerPage,
        query: searchTerm,
        start_date: startDate,
        end_date: endDate,
        id_pelanggan: selectedCustomer ? selectedCustomer.value : "",
        id_user: selectedUser ? selectedUser.value : "",
        id_asset: selectedAsset ? selectedAsset.value : "",
        is_temuan: selectedTemuan ? selectedTemuan.value : "",
      };

      const resp = await reportSvc.getReports({
        params: { ...params },
      });
      if (resp.status === 200) {
        setReports(resp.data.data);
        setStartIndex((activePage - 1) * itemsPerPage);
        setEndIndex(startIndex + itemsPerPage);
        setTotalPages(resp.data.totalPage);
        setTotalRows(resp.data.totalRows);
      }
    } catch (error) {
      let message =
        error.response?.data?.message || "Gagal mengambil data report";
      showErrorAlert("Gagal", message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAssets = async () => {
    try {
      setIsLoadingFilter(true);
      const resp = await assetSvc.getAsset({
        params: {
          page: 0,
          limit: 1000,
        },
      });
      if (resp.status === 200) {
        setAssets(resp.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoadingFilter(false);
    }
  };

  const fetchUser = async () => {
    try {
      setIsLoadingFilter(true);
      const resp = await userSvc.getUser({
        params: {
          page: 0,
          limit: 1000,
          role: "operator",
        },
      });
      if (resp.status === 200) {
        setUsers(resp.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoadingFilter(false);
    }
  };

  const fetchCustomers = async () => {
    try {
      setIsLoadingFilter(true);
      const resp = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 10000,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoadingFilter(false);
    }
  };

  useEffect(() => {
    if (getLoginData()?.user?.role == "admin") {
      fetchCustomers();
    }
    fetchUser();
    fetchAssets();
  }, []);

  useEffect(() => {
    fetchReports();
  }, [
    startDate,
    endDate,
    selectedAsset,
    selectedCustomer,
    selectedTemuan,
    selectedUser,
    activePage,
    itemsPerPage,
  ]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;

      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          fetchReports();
        }, 500);
      };

      delayedFetch();

      return () => {
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedUser("");
    setSelectedAsset("");
    setSelectedCustomer("");
    setEndDate(moment().format("YYYY-MM-DD"));
    setSelectedTemuan("");
    setStartDate(moment().format("YYYY-MM-DD"));
    setActivePage(1);

    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }

    fetchReports();
  };

  const truncateAddress = (address) => {
    const maxLength = 50; // Set the maximum length for the address
    if (address.length <= maxLength) {
      return address;
    }
    return address.substring(0, maxLength) + "...";
  };

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const handleSelectTemuan = (selectedOption) => {
    setSelectedTemuan(selectedOption);
  };

  const handleSelectAsset = (selectedOption) => {
    setSelectedAsset(selectedOption);
  };
  const handleSelectUser = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const customPaddingStyle = {
    padding: "0.75rem 0.5rem",
  };

  return (
    <div
      className="align-items-center py-4 w-100"
      // tabIndex={0}
      // onKeyDown={handleKeyPress}
    >
      <Card border="light" className="shadow-sm">
        <Card.Body className="pb-0">
          <Card.Title className="">Riwayat Laporan</Card.Title>
          <hr />
          <Row className="align-items-center">
            <Form>
              <div className="mb-4">
                <Form.Group id="range">
                  <Form.Label className="text-primary fs-6">
                    Pilih rentang tanggal
                  </Form.Label>
                  <Row style={{ width: "100%" }}>
                    <Col>
                      <Form.Label>Tanggal dimulai</Form.Label>
                      <Datetime
                        timeFormat={false}
                        onChange={handleStartDateChange}
                        renderInput={(props, openCalendar) => (
                          <InputGroup className="me-2">
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={
                                startDate
                                  ? moment(startDate).format("YYYY-MM-DD")
                                  : ""
                              }
                              placeholder="YYYY-MM-DD"
                              onFocus={openCalendar}
                              onChange={() => {}}
                            />
                          </InputGroup>
                        )}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Tanggal berakhir</Form.Label>
                      <Datetime
                        timeFormat={false}
                        onChange={handleEndDateChange}
                        renderInput={(props, openCalendar) => (
                          <InputGroup className="me-2">
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={
                                endDate
                                  ? moment(endDate).format("YYYY-MM-DD")
                                  : ""
                              }
                              placeholder="YYYY-MM-DD"
                              onFocus={openCalendar}
                              onChange={() => {}}
                            />
                          </InputGroup>
                        )}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </div>
            </Form>
          </Row>
          {isLoadingFilter ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "300px" }}
            >
              <output>
                <Spinner animation="border" />
                <span className="visually-hidden">Loading...</span>
              </output>
            </div>
          ) : (
            <div className="mb-3">
              <Row
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  margin: "0 3px",
                }}
              >
                <Col
                  style={{
                    minWidth: "125px",
                    padding: "0 3px 0 0",
                  }}
                >
                  <FormControl
                    type="text"
                    placeholder="Cari operator, ATM / kode ATM"
                    className="me-1"
                    style={{ height: "52px" }}
                    ref={searchInputRef}
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </Col>
                <Col style={{ minWidth: "125px", padding: "0 5px 10px 0" }}>
                  <InputGroup className="me-1">
                    <Select
                      id="state"
                      placeholder="Semua Temuan"
                      styles={customStyles}
                      value={selectedTemuan}
                      onChange={handleSelectTemuan}
                      // isClearable
                      options={[
                        { value: "", label: "Semua Laporan" },
                        { value: "true", label: "Temuan" },
                        { value: "false", label: "Tanpa Temuan" },
                      ]}
                    />
                  </InputGroup>
                </Col>
                {user.role == "admin" && (
                  <Col style={{ minWidth: "125px", padding: "0 5px 10px 0" }}>
                    <InputGroup className="me-1">
                      <Select
                        id="state"
                        placeholder="Semua Pelanggan"
                        styles={customStyles}
                        value={selectedCustomer}
                        onChange={handleSelectCustomer}
                        // isClearable
                        options={customers.map((user) => ({
                          value: user.id_pelanggan,
                          label: user.name,
                        }))}
                      />
                    </InputGroup>
                  </Col>
                )}
                <Col style={{ minWidth: "125px", padding: "0 5px 10px 0" }}>
                  <InputGroup className="me-1">
                    <Select
                      id="state"
                      placeholder="Semua Operator"
                      styles={customStyles}
                      value={selectedUser}
                      onChange={handleSelectUser}
                      // isClearable
                      options={users.map((user) => ({
                        value: user.id_user,
                        label: user.name,
                      }))}
                    />
                  </InputGroup>
                </Col>
                <Col style={{ minWidth: "125px", padding: "0 5px 10px 0" }}>
                  <InputGroup className="me-1">
                    <Select
                      className="me-1"
                      id="state"
                      placeholder="Semua ATM"
                      styles={customStyles}
                      value={selectedAsset}
                      // isClearable
                      onChange={handleSelectAsset}
                      options={assets?.map((asset) => ({
                        value: asset.id_asset,
                        label: asset.name,
                      }))}
                    />
                  </InputGroup>
                </Col>
                <Col style={{ maxWidth: "90px", padding: "0 5px 10px 0" }}>
                  <Button
                    variant="light"
                    size="sm"
                    onClick={handleClearFilters}
                  >
                    {`Reset Filter`}
                  </Button>
                </Col>
              </Row>
            </div>
          )}

          <Table
            responsive
            className="table-centered table-nowrap rounded mb-0"
          >
            <thead className="thead-light">
              <tr>
                <th className="border-0" style={customPaddingStyle}>
                  Nama Operator
                </th>
                <th className="border-0" style={customPaddingStyle}>
                  ATM
                </th>
                <th className="border-0" style={customPaddingStyle}>
                  Lokasi
                </th>
                <th className="border-0" style={customPaddingStyle}>
                  Kunjungan
                </th>
                <th className="border-0" style={customPaddingStyle}>
                  Temuan
                </th>
                <th className="border-0" style={customPaddingStyle}>
                  Tanggal
                </th>
                <th className="border-0" style={customPaddingStyle}>
                  Aksi
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tr>
                <td
                  colSpan={6}
                  className="text-center"
                  style={{ height: "300px" }}
                >
                  <output>
                    <Spinner animation="border">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </output>
                </td>
              </tr>
            ) : totalRows > 0 ? (
              <tbody>
                {reports.map((report) => (
                  <tr key={report.id_laporan}>
                    <td className="border-0" style={customPaddingStyle}>
                      <a
                        href={`/user/detail/${report.id_user}`}
                        rel="noopener noreferrer"
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {report.user_photo_url ? (
                            <Image
                              src={`${report.user_photo_url}`}
                              alt="profile-pict"
                              width="40px"
                              height="40px"
                              style={{
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <div
                              className="bg-primary"
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: "orange",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: "10px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#fff",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                }}
                              >
                                {report.user_name
                                  ? report.user_name.charAt(0)
                                  : ""}
                              </span>
                            </div>
                          )}
                          <div>
                            <span className="fw-bold">{report.user_name}</span>
                            <br />
                            Operator
                          </div>
                        </div>
                      </a>
                    </td>

                    <td className="border-0" style={customPaddingStyle}>
                      <a
                        href={`/asset/detail/${report.id_asset}`}
                        rel="noopener noreferrer"
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        <span className="fw-bolder">{report.asset_name}</span>
                        <br />
                        {report.asset_kode}
                      </a>
                    </td>
                    <td className="border-0" style={customPaddingStyle}>
                      <span className="fw-bolder">
                        {truncateAddress(report.asset_alamat)}
                      </span>
                      <br />
                      {report.pelanggan_name}
                    </td>
                    <td
                      className="border-0 text-center"
                      style={customPaddingStyle}
                    >
                      <span className="fw-bolder">{report.kunjungan}</span>
                    </td>
                    <td className="border-0" style={customPaddingStyle}>
                      <div>
                        {report.is_temuan ? (
                          <div className="bg-danger text-white rounded-pill p-2 text-center">
                            temuan
                          </div>
                        ) : (
                          <div className="bg-success text-white rounded-pill p-2 text-center">
                            tidak
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="fw-bold border-0" style={customPaddingStyle}>
                      <span>
                        {moment.utc(report.date_done).format("YYYY-MM-DD")}
                      </span>
                      <br />
                      {moment.utc(report.date_done).format("HH:mm:ss")}
                    </td>
                    <td className="fw-bold border-0" style={customPaddingStyle}>
                      <a
                        href={`/report-detail/${report.id_laporan}`}
                        rel="noopener noreferrer"
                        className="fw-bolder"
                      >
                        Lihat Detail
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-center"
                  style={{ height: "200px" }}
                >
                  <span className="text-muted">Tidak ada laporan</span>
                </td>
              </tr>
            )}
          </Table>
        </Card.Body>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <small className="me-2">Show:</small>
            <Form.Select
              className="me-3"
              style={{ width: "70px" }}
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
            </Form.Select>
            <small className="fw-bold">
              Menampilkan {startIndex + 1} -{" "}
              {Math.min(startIndex + itemsPerPage, totalRows)} dari {totalRows}{" "}
              laporan
            </small>
          </div>

          <div className="d-flex align-items-center">
            <Nav className="mx-2">
              <Pagination className="mb-2 mb-lg-0">
                {renderPaginationItems()}
              </Pagination>
            </Nav>
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
};
