import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faSignOutAlt,
  faTimes,
  faUser,
  faBuilding,
  faDatabase,
  faLink,
  faFile,
  faList,
  faBook,
  faUserLock,
  faDownload,
  faArchive,
  faCoins,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Nav, Badge, Image, Button, Navbar } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import GmeLogo from "../assets/img/icons/gme-icon.svg";
// import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import { getLoginData, removeLoginData } from "../services/authSvc";
import { DashboardTwoTone } from "@ant-design/icons";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const { user } = getLoginData();

  const onCollapse = () => setShow(!show);

  const handleLogout = () => {
    removeLoginData();
    window.location.href = "/login";
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";

    // Pengecekan untuk menentukan apakah "NavItem" aktif
    let isActive = link === pathname;

    // Tambahan pengecekan khusus untuk "Infografis Laporan"
    if (title === "Infografis Laporan") {
      isActive =
        pathname === Routes.ReportSummary.path ||
        pathname === Routes.AssetSummary.path ||
        pathname === Routes.OperatorSummary.path;
    }

    if (title === "Pengguna") {
      isActive =
        pathname === Routes.User.path ||
        pathname === Routes.User.path + "/history";
      // pathname === Routes.OperatorSummary.path;
    }

    const navItemClassName = isActive ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              icon?.prefix ? (
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={icon} />{" "}
                </span>
              ) : (
                <span className="sidebar-icon">{icon}</span>
              )
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text text-sm">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        className={`navbar-light px-4 d-md-none d-flex justify-content-between ${
          show ? "position-static" : "position-sticky top-0"
        }`}
        style={
          !show
            ? {
                zIndex: 1000,
                backgroundColor: "#f5f8fb",
                borderRadius: "8px",
                boxShadow: "0 4px 2px -2px rgba(0, 0, 0, 0.1)",
              }
            : {}
        }
      >
        <div className="d-flex align-items-center">
          <Navbar.Brand
            className="me-lg-5"
            as={Link}
            to={Routes.DashboardOverview.path}
          >
            <Image src={ReactHero} className="navbar-brand-light" />
          </Navbar.Brand>
          <img
            src={GmeLogo}
            alt="logo"
            className="my-2"
            style={{ height: "30px" }}
          />
        </div>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
          style={{ border: "none !important" }}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
        {/* <div aria-controls="main-navbar" onClick={onCollapse}>
          <FontAwesomeIcon icon={faBars} />
        </div> */}
      </Navbar>

      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  {user.photo_url ? (
                    <Image
                      src={user.photo_url}
                      className="user-avatar md-avatar rounded-circle"
                    />
                  ) : (
                    <div className="user-avatar md-avatar rounded-circle bg-primary">
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        {user.name.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  )}
                </div>
                <div className="d-block">
                  <h6 className="text-dark">Hi, {user.name}</h6>
                  <Button
                    onClick={handleLogout}
                    variant="primary"
                    size="xs"
                    className="text-light"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none text-dark"
                onClick={onCollapse}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "30px",
                    height: "30px",
                    transition: "color 0.3s",
                    "&:hover": {
                      color: "#f76617",
                    },
                  }}
                />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0 pb-5">
              {/* <NavItem title="Volt React" link={Routes.Presentation.path} image={ReactHero} /> */}
              <div className="m-auto  row text-center">
                <img
                  src={GmeLogo}
                  alt="logo"
                  className="m-auto"
                  style={{ width: "100px" }}
                />
                <h6 className="col mt-2 text-dark">GME Monitoring</h6>
              </div>

              <hr className="mt-2 border-dark " />
              <div className="text-primary mb-2" style={{ fontSize: "14px" }}>
                <strong>Beranda</strong>
              </div>
              {user.role === "operator" ? (
                <>
                  <NavItem
                    title="Upload Laporan"
                    icon={faFile}
                    link={
                      Routes.OperatorLaporan.path ||
                      Routes.OperatorLaporan.path ||
                      Routes.OperatorLaporan.path
                    }
                  />
                  <NavItem
                    title="Token EMR"
                    icon={faCoins}
                    link={Routes.OperatorEmr.path}
                  />
                </>
              ) : (
                ""
              )}

              {user.role !== "operator" ? (
                <>
                  <NavItem
                    title="Dashboard"
                    link={Routes.DashboardOverview.path}
                    icon={faChartPie}
                  />
                  <NavItem
                    title="Laporan"
                    icon={faFile}
                    link={Routes.Report.path}
                  />
                  <NavItem
                    title="Infografis Laporan"
                    icon={faDownload}
                    link={
                      Routes.ReportSummary.path ||
                      Routes.AssetSummary.path ||
                      Routes.OperatorSummary.path
                    }
                  />
                  <NavItem
                    title="Download Laporan"
                    icon={faDownload}
                    link={Routes.Export.path}
                  />
                </>
              ) : (
                ""
              )}

              {user.role === "admin" || user.role === "admin cabang" ? (
                <NavItem
                  title="Ekspor Link Laporan"
                  icon={faFile}
                  link={Routes.ExportPDFAsset.path}
                />
              ) : (
                ""
              )}

              {user.role !== "operator" ? (
                <>
                  <hr className="mt-2 border-dark " />
                  <div
                    className="text-primary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Kelola</strong>
                  </div>
                  <NavItem
                    title="ATM"
                    icon={faDatabase}
                    link={Routes.Asset.path}
                  />
                  <NavItem
                    title="Pengguna"
                    icon={faUser}
                    link={
                      Routes.User.path ||
                      Routes.AssetSummary.path ||
                      Routes.OperatorSummary.path
                    }
                  />
                  {user.role === "admin" ? (
                    <>
                      <NavItem
                        title="Role User-ATM"
                        icon={faUserLock}
                        link={Routes.UserAsset.path}
                      />
                      <NavItem
                        title="Kantor Cabang"
                        icon={faBuilding}
                        link={Routes.Customer.path}
                      />
                      <NavItem
                        title="Jenis Dokumentasi"
                        icon={faBook}
                        link={Routes.DocType.path}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {user.role !== "operator" ? (
                <>
                  <hr className="mt-2 border-dark " />
                  <div
                    className="text-primary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Transaksi</strong>
                  </div>
                  <NavItem
                    title="Transaksi"
                    icon={faDatabase}
                    link={Routes.report.transaction.path}
                  />
                </>
              ) : (
                ""
              )}

              {user.role === "admin" ? (
                <>
                  <hr className="mt-2 border-dark " />
                  <div
                    className="text-primary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>EMR</strong>
                  </div>
                  <NavItem
                    title="EMR Tool"
                    icon={faLink}
                    link={Routes.EMR.path}
                  />

                  <NavItem
                    title="Daftar EMR"
                    icon={faList}
                    link={Routes.EMRList.path}
                  />
                  <NavItem
                    title="Daftar EMR V2"
                    icon={faList}
                    link={Routes.EMRV2List.path}
                  />
                </>
              ) : (
                ""
              )}
              {user.role === "admin" ? (
                <>
                  <hr className="mt-2 border-dark " />
                  <div
                    className="text-primary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Data Logs</strong>
                  </div>
                  <NavItem
                    title="Logs"
                    icon={faArchive}
                    link={Routes.Logs.path}
                  />
                </>
              ) : (
                ""
              )}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
