import React, { useEffect, useState } from "react";
import { Button, Descriptions, Image, Skeleton, Table, Tooltip } from "antd";
import { useHistory, useParams } from "react-router-dom";
// import productSvc from "../../services/productSvc";
// import SpinnerLoading from "../../components/Spinner";
// import Helper from "../../utils/helper";
import moment from "moment";
// import TitleComponent from "../../components/TitleComponent";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPen, faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { getLoginData } from "../../services/authSvc";
// import userSvc from "../../services/userSvc";
// import buyerSvc from "../../services/buyerSvc";
import reportTransactionSvc from "../../services/reportTransactionSvc";
import SpinnerLoading from "../../components/loading/SpinnerLoading";
import { Card } from "@themesberg/react-bootstrap";

const { user } = getLoginData();

const ViewTransaction = () => {
  const params = useParams();
  const history = useHistory();
  const id = Number(params.id);
  const [loading, setLoading] = useState(true);
  const [reportTransaction, setReportTransaction] = useState([]);
  const [lastUpdated, setLastUpdated] = useState();
  // Helper.formatDate(moment.now(), "YYYY-MM-DD HH:mm") + " WIB"

  const fetchTransaction = async () => {
    try {
      setLoading(true);
      const resp = await reportTransactionSvc.getReportTransactionById(id, {});

      if (resp.status === 200) {
        setReportTransaction(resp.data.data);
      }
      setLoading(false);
    } catch (err) {}
  };
  useEffect(() => {
    fetchTransaction();
  }, []);

  const items = [
    {
      key: "1",
      label: "Nama Laporan",
      children: reportTransaction?.name,
    },
    {
      key: "2",
      label: "Deskripsi",
      span: 2,
      children: reportTransaction.description,
    },
    {
      key: "3",
      label: "Harga",
      children: reportTransaction.price,
    },
    {
      key: "41",
      label: "Nama ATM",
      children: (
        <Button
          type="link"
          onClick={(e) => {
            history.push(`/asset/detail/${reportTransaction?.asset?.id_asset}`);
          }}
          style={{ padding: 0, color: "#f76617" }}
        >
          {reportTransaction.asset?.name}
        </Button>
      ),
    },
    {
      key: "411",
      label: "Nama Cabang",
      children: reportTransaction?.pelanggan?.name,
    },
    {
      key: "4111",
      label: "Foto Sebelum",
      children: reportTransaction?.report_transaction_images
        ?.filter((image) => image.image_type === "before")
        ?.map((image) => (
          <Image
            key={image.image_url}
            src={image.image_url}
            alt="image before"
            style={{ width: "100%", height: "auto", objectFit: "contain" }}
          />
        )),
    },
    {
      key: "41111",
      label: "Foto Setelah",
      children: reportTransaction?.report_transaction_images
        ?.filter((image) => image.image_type === "after")
        ?.map((image) => (
          <Image
            key={image.image_url}
            src={image.image_url}
            alt="image after"
            width={200}
          />
        )),
    },
    // {
    //   key: "4",
    //   label: "Jenis Kendaraan",
    //   children: (
    //     <span>
    //       {reportTransaction?.product?.name
    //         ? reportTransaction.product.name
    //         : "Belum ada jenis kendaraan"}
    //     </span>
    //   ),
    // },
  ];

  const columns = [
    {
      title: "No Order",
      dataIndex: "invoice_number",
      key: "invoice_number",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "Kasir",
      dataIndex: "user_name",
      key: "user_name",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "Item Pasir",
      dataIndex: "product_name",
      key: "product_name",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "Jumlah",
      dataIndex: "total_items",
      key: "total_items",
      render: (text, record) => <span>{String(text)}</span>,
    },
    // {
    //   title: "Total Harga",
    //   dataIndex: "total_price",
    //   key: "total_price",
    //   render: (text, record) => (
    //     <span>{String(Helper.convertIntToRupiahString(text))}</span>
    //   ),
    // },
    // {
    //   title: "Uang Tunai",
    //   dataIndex: "amount_cash",
    //   key: "amount_cash",
    //   render: (text, record) => (
    //     <span>{String(Helper.convertIntToRupiahString(text))}</span>
    //   ),
    // },
    {
      title: "Pembeli",
      dataIndex: "buyer",
      key: "buyer",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "No Plat",
      dataIndex: "buyer_plat_number",
      key: "buyer_plat_number",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "Tanggal",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <span>
          {moment(text).format("YYYY-MM-DD")}
          <br></br>
          {moment(text).format("HH:mm:ss")}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status_payment",
      key: "status_payment",
      render: (text, record) => (
        <span
          style={{ color: record.status_payment === "lunas" ? "green" : "Red" }}
        >
          {text}
        </span>
      ),
    },
  ];

  //   const fetchTransactions = async () => {
  //     try {
  //       setLoading(true);
  //       const resp = await reportTransactionSvc.getTransaction({
  //         params: {
  //           page: 0,
  //           limit: 5,
  //           id_report_transaction: id_buyer,
  //         },
  //       });
  //       if (resp.status === 200) {
  //         setTransactions(resp.data.data);
  //         // setLastUpdated(
  //         //   Helper.formatDate(Date.now(), "DD/MM/YYYY HH:mm") + " WIB"
  //         // );
  //       }
  //       setLoading(false);
  //     } catch (err) {}
  //   };

  //   useEffect(() => {
  //     fetchTransaction();
  //   }, []);

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body className="pb-0">
          <Card.Title className="">Detail transaksi Pengerjaan</Card.Title>
          <hr />
          {loading ? (
            <SpinnerLoading height="h-40" />
          ) : (
            <>
              <Descriptions layout="vertical" items={items} />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ViewTransaction;
