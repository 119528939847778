import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseMedicalCircleExclamation,
  faMapMarked,
  faPenToSquare,
  faPlus,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Card,
  Table,
  Form,
  InputGroup,
  Spinner,
  Pagination,
  Nav,
  FormControl,
} from "@themesberg/react-bootstrap";
import Select from "react-select";

import { useEffect } from "react";
import Swal from "sweetalert2";
import FormAsset from "../components/FormAsset";
import assetSvc from "../services/assetSvc";
import customerSvc from "../services/customerSvc";
import { useHistory } from "react-router-dom";
import { getLoginData } from "../services/authSvc";
import docTypeSvc from "../services/docTypeSvc";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";
import { customStyles } from "../utils/SelectUtils";

export default () => {
  const searchInputRef = useRef(null);
  const [assets, setAssets] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [totalRows, setTotalRows] = useState("");
  const history = useHistory();
  const { user } = getLoginData();
  const role = user?.role;
  const dev = process.env.REACT_APP_UI_DEV;

  const renderPaginationItems = () => {
    const paginationItems = [];

    // Menambahkan tombol First
    paginationItems.push(
      <Pagination.First
        key="first"
        onClick={() => handlePageChange(1)}
        disabled={activePage === 1}
      />
    );

    // Menambahkan tombol Prev
    paginationItems.push(
      <Pagination.Prev
        key="prev"
        onClick={() => activePage > 1 && handlePageChange(activePage - 1)}
        disabled={activePage === 1}
      />
    );

    // Menambahkan elemen Pagination.Item untuk setiap halaman
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 || // Menampilkan halaman pertama
        i === totalPages || // Menampilkan halaman terakhir
        (i >= activePage - 2 && i <= activePage + 2) // Menampilkan halaman di sekitar halaman aktif
      ) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageChange(i)}
            active={i === activePage}
          >
            {i}
          </Pagination.Item>
        );
      } else if (
        (i === activePage - 3 && i > 2) || // Menampilkan halaman dengan jarak -3 dari halaman aktif
        (i === activePage + 3 && i < totalPages - 1) // Menampilkan halaman dengan jarak +3 dari halaman aktif
      ) {
        paginationItems.push(<Pagination.Ellipsis key={i} />);
      }
    }

    // Menambahkan tombol Next
    paginationItems.push(
      <Pagination.Next
        key="next"
        onClick={() =>
          activePage < totalPages && handlePageChange(activePage + 1)
        }
        disabled={activePage === totalPages}
      />
    );

    // Menambahkan tombol Last
    paginationItems.push(
      <Pagination.Last
        key="last"
        onClick={() => handlePageChange(totalPages)}
        disabled={activePage === totalPages}
      />
    );

    return paginationItems;
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setActivePage(1); // Reset halaman aktif ke halaman pertama
  };

  const fetchAssets = async (page, limit) => {
    setIsLoading(true);
    const resp = await assetSvc.getAsset({
      params: {
        page: page - 1,
        limit: limit,
        query: searchTerm ? searchTerm : null,
        active:
          selectedStatus === "active"
            ? true
            : selectedStatus === "inactive"
            ? false
            : null,
        id_pelanggan: selectedCustomer ? selectedCustomer.value : null,
        fields: undefined,
        includes: JSON.stringify([
          {
            model: "pelanggan",
            attributes: ["name"],
          },
        ]),
      },
    });
    if (resp.status === 200) {
      setAssets(resp.data.data);
      setStartIndex((activePage - 1) * itemsPerPage);
      setEndIndex(startIndex + itemsPerPage);
      setTotalPages(resp.data.totalPage);
      setTotalRows(resp.data.totalRows);
    }

    setIsLoading(false);
  };

  const fetchAssetsRemoveFilter = async (page, limit) => {
    setIsLoading(true);
    const resp = await assetSvc.getAsset({
      params: {
        page: page - 1,
        limit: limit,
        query: searchTerm ? searchTerm : null,
        active:
          selectedStatus === "active"
            ? true
            : selectedStatus === "inactive"
            ? false
            : null,
        id_pelanggan: selectedCustomer ? selectedCustomer.value : null,
        fields: undefined,
        includes: JSON.stringify([
          {
            model: "pelanggan",
            attributes: ["name"],
          },
        ]),
      },
    });
    if (resp.status === 200) {
      setAssets(resp.data.data);
      setStartIndex((activePage - 1) * itemsPerPage);
      setEndIndex(startIndex + itemsPerPage);
      setTotalPages(resp.data.totalPage);
      setTotalRows(resp.data.totalRows);
    }

    setIsLoading(false);
  };

  const fetchCustomers = async () => {
    if (user?.role === "admin") {
      const resp = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
          // active: true,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const isFirstRenderAssets = useRef(true);
  useEffect(() => {
    if (!isFirstRenderAssets.current) {
      fetchAssets(activePage, itemsPerPage);
    } else {
      isFirstRenderAssets.current = false;
    }
  }, [activePage, itemsPerPage]);

  useEffect(() => {
    fetchAssets();
  }, [selectedCustomer, selectedStatus]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchAssets();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500); // Adjust the delay time as per your needs
      };
      delayedFetch(); // Call the delayedFetch initially to trigger the initial API call
      return () => {
        // Clean up by clearing the timer when the component unmounts
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedStatus("");
    setSelectedCustomer("");
    setActivePage(1);
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }

    // Perform search with cleared filters
    fetchAssetsRemoveFilter();
  };

  const handleActive = async (id) => {
    const willUpdate = await Swal.fire({
      title: "Konfirmasi Perubahan?",
      text: "Status asset akan berubah setelah update!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Lanjutkan!",
      cancelButtonText: "Batalkan",
    });

    if (willUpdate.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Mengubah status aset...");
        const resp = await assetSvc.updateActivateAsset(id);
        if (resp.status === 200) {
          fetchAssets(activePage, itemsPerPage); // Pass the activePage and itemsPerPage values
          showSuccessAlert("Berhasil", "Data status aset berhasil");
        }
      } catch (error) {
        let message =
          error.response.data.message || "Gagal mengubah status aset";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleActiveGPS = async (id) => {
    const willUpdate = await Swal.fire({
      title: "Konfirmasi Perubahan?",
      text: "Status GPS ATM akan berubah setelah update!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Lanjutkan!",
      cancelButtonText: "Batalkan",
    });

    if (willUpdate.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Mengubah status GPS...");
        const resp = await assetSvc.updateActivateAssetGPS(id);
        if (resp.status === 200) {
          fetchAssets(activePage, itemsPerPage); // Pass the activePage and itemsPerPage values
          showSuccessAlert("Berhasil", "Data status GPS berhasil");
        }
      } catch (error) {
        let message =
          error.response.data.message || "Gagal mengubah status GPS";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleAddAsset = () => {
    history.push("/asset/add");
  };

  const handleEditAsset = async (id) => {
    history.push(`/asset/update/${id}`);
  };

  const truncateAddress = (address) => {
    const maxLength = 50; // Set the maximum length for the address
    if (address.length <= maxLength) {
      return address;
    }
    return address.substring(0, maxLength) + "...";
  };

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const customPaddingStyle = {
    padding: "0.75rem 0.5rem",
  };
  return (
    <div className="align-items-center py-4 w-100">
      <Card border="light" className="shadow-sm">
        <Card.Body className="pb-0">
          <Card.Title className="">ATM</Card.Title>
          <hr />
          {role === "admin" && dev === "developer" && (
            <div className="d-flex mb-3">
              <Button
                variant="success"
                size="sm"
                className=""
                style={{ width: "100px" }}
                onClick={handleAddAsset}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="me-2"
                  style={{ fontSize: "14px" }}
                />
                Tambah
              </Button>
            </div>
          )}
          <div className="d-flex justify-content-between mb-3">
            <FormControl
              ref={searchInputRef}
              type="text"
              placeholder="Cari nama atm, lokasi, kode, dll..."
              className="me-1"
              // value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            <InputGroup>
              <Form.Select
                id="state"
                className="me-1"
                defaultValue=""
                value={selectedStatus}
                style={{ width: "30px" }}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                }}
              >
                <option value="">Semua Status</option>
                <option value="active">Status Aktif</option>
                <option value="inactive">Status Nonaktif</option>
              </Form.Select>
            </InputGroup>
            {user?.role == "admin" && (
              <InputGroup>
                <Select
                  className="me-1"
                  id="state"
                  placeholder="Semua Kantor Cabang"
                  styles={customStyles}
                  value={selectedCustomer}
                  onChange={handleSelectCustomer}
                  options={customers.map((user) => ({
                    value: user.id_pelanggan,
                    label: user.name,
                  }))}
                />
              </InputGroup>
            )}
            <Button
              variant="secondary"
              size="sm"
              className="ml-2"
              onClick={handleClearFilters}
            >
              Reset Filter
            </Button>
          </div>

          <Table
            responsive
            className="table-centered table-nowrap rounded mb-0"
          >
            <thead className="thead-light">
              <tr>
                <th className="border-0" style={customPaddingStyle}>
                  Nama ATM
                </th>
                <th className="border-0" style={customPaddingStyle}>
                  Lokasi
                </th>
                <th className="border-0" style={customPaddingStyle}>
                  Kepemilikan
                </th>
                <th className="border-0" style={customPaddingStyle}>
                  Ring
                </th>
                <th className="border-0" style={customPaddingStyle}>
                  Status
                </th>
                <th className="border-0" style={customPaddingStyle}>
                  GPS
                </th>
                {role === "admin" && dev === "developer" && (
                  <th className="border-0" style={customPaddingStyle}>
                    Aksi
                  </th>
                )}
                <th className="border-0" style={customPaddingStyle}>
                  Detail
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tr>
                <td
                  colSpan={5}
                  className="text-center"
                  style={{ height: "300px" }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </td>
              </tr>
            ) : totalRows > 0 ? (
              <tbody>
                {assets.map((asset, index) => (
                  <tr key={index}>
                    <td className="border-0" style={customPaddingStyle}>
                      <span className="fw-bolder">{asset?.name}</span>
                      <br />
                      {asset?.kode}
                    </td>
                    <td className="border-0" style={customPaddingStyle}>
                      <span className="fw-bolder">{asset.lokasi}</span>
                      <br />
                      {truncateAddress(asset?.alamat)}
                    </td>
                    <td className="border-0" style={customPaddingStyle}>
                      <span className="fw-bolder">
                        {asset?.pelanggan?.name
                          .split(" ")
                          .slice(0, -1)
                          .join(" ")}
                      </span>
                      <br />
                      <span className="fw-bolder">
                        {asset?.pelanggan?.name?.split(" ").slice(-1)}
                      </span>
                    </td>
                    <td className="border-0" style={customPaddingStyle}>
                      <span className="fw-bolder">{asset.ring}</span>
                    </td>
                    <td className="border-0" style={customPaddingStyle}>
                      <div>
                        {asset.active ? (
                          <div className="bg-success text-white rounded-pill p-2 text-center">
                            Aktif
                          </div>
                        ) : (
                          <div className="bg-danger text-white rounded-pill p-2 text-center">
                            Nonaktif
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="border-0" style={customPaddingStyle}>
                      <div>
                        {asset.gps_active ? (
                          <div className="bg-success text-white rounded-pill p-2 text-center">
                            Aktif
                          </div>
                        ) : (
                          <div className="bg-danger text-white rounded-pill p-2 text-center">
                            Nonaktif
                          </div>
                        )}
                      </div>
                    </td>

                    {role === "admin" && dev === "developer" && (
                      <td className="border-0" style={customPaddingStyle}>
                        <div className="d-flex align-items-center">
                          <div
                            className="inline-block"
                            style={{
                              border: "12px solid transparent",
                              margin: "-12px",
                              "--c81fc0a4": "9999",
                              marginRight: "3px",
                            }}
                          >
                            <button className="bg-white hover:bg-bs-bg block border border-bs-line rounded p-1.5">
                              <div
                                className="logo-button"
                                onClick={(e) => {
                                  handleEditAsset(asset.id_asset);
                                }}
                                title="Ubah Data Asset"
                                style={{ cursor: "pointer" }}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </div>
                            </button>
                          </div>

                          <div
                            className="inline-block"
                            style={{
                              border: "12px solid transparent",
                              margin: "-12px",
                              "--c81fc0a4": "9999",
                              marginRight: "3px",
                            }}
                          >
                            <button className="bg-white hover:bg-bs-bg block border border-bs-line rounded p-1.5">
                              <div
                                className="logo-button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleActive(asset.id_asset);
                                }}
                                title={
                                  asset.active ? "Nonaktifkan" : "Aktifkan"
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <FontAwesomeIcon icon={faWrench} />
                              </div>
                            </button>
                          </div>

                          <div
                            className="inline-block"
                            style={{
                              border: "12px solid transparent",
                              margin: "-12px",
                              "--c81fc0a4": "9999",
                            }}
                          >
                            <button className="bg-white hover:bg-bs-bg block border border-bs-line rounded p-1.5">
                              <div
                                className="logo-button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleActiveGPS(asset.id_asset);
                                }}
                                title={
                                  asset.active
                                    ? "Nonaktifkan GPS"
                                    : "Aktifkan GPS"
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <FontAwesomeIcon icon={faMapMarked} />
                              </div>
                            </button>
                          </div>
                        </div>
                      </td>
                    )}
                    <td className="fw-bold border-0" style={customPaddingStyle}>
                      <a
                        href={`/asset/detail/${asset.id_asset}`}
                        rel="noopener noreferrer"
                        className="fw-bolder"
                      >
                        Lihat
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-center"
                  style={{ height: "200px" }}
                >
                  <span className="text-muted">Tidak ada ATM</span>
                </td>
              </tr>
            )}
          </Table>
        </Card.Body>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <small className="me-2">Limit:</small>
            <Form.Select
              className="me-3"
              style={{ width: "70px" }}
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
            </Form.Select>
            <small className="fw-bold">
              Menampilkan {startIndex + 1} -{" "}
              {Math.min(startIndex + itemsPerPage, totalRows)} dari {totalRows}{" "}
              Assets
            </small>
          </div>

          <div className="d-flex align-items-center">
            <Nav className="mx-2">
              <Pagination className="mb-2 mb-lg-0">
                {renderPaginationItems()}
              </Pagination>
            </Nav>
            {/* <small className="fw-bold">
                Showing <b>{startIndex + 1} - {endIndex}</b> of <b>{assets.length}</b> assets
              </small> */}
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
};
