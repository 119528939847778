import {
  Card,
  Col,
  Container,
  Image,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { showErrorAlert } from "../../utils/SwalUtil";
import assetSvc from "../../services/assetSvc";

const DetailAsset = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [asset, setAsset] = useState([]);
  moment.tz.setDefault("Asia/Bangkok");
  const fetchDetailAsset = async () => {
    setIsLoading(true);
    try {
      const resp = await assetSvc.getAssetById(id);

      if (resp.status === 200) {
        setAsset(resp.data.data);
      }
    } catch (error) {
      showErrorAlert("Gagal", "Gagal mengambil data asset");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getDetail = async () => {
      await fetchDetailAsset();
    };

    getDetail();
  }, []);

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Detail Asset</h5>
      </Card.Header>
      <Card.Body>
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : asset.name ? (
          <Container>
            <Row>
              <Col>
                <div className="mb-4">
                  <span className="h5">{asset.name}</span>
                  <p>{asset.kode}</p>
                  <div>
                    <span className="text-muted text-muted">Alamat</span>
                    <br />
                    <p className="h6">{asset.alamat}</p>
                  </div>
                  <div>
                    <span className="text-muted">Koordinat</span>
                    <br />
                    <p className="h6">
                      {asset.longitude}, {asset.latitude}
                    </p>
                  </div>
                  <div>
                    <span className="text-muted">Ring</span>
                    <br />
                    <p className="h6">{asset.ring}</p>
                  </div>
                  <div>
                    <span className="text-muted">Status</span>
                    <br />
                    <div>
                      {asset.active ? (
                        <p className="text-success fw-bolder">Aktif</p>
                      ) : (
                        <p className="text-danger fw-bolder">Nonaktif</p>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              {asset.pelanggan ? (
                <Col>
                  <div>
                    <h5>Kepemilikan</h5>
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="mb-2">
                      {asset.pelanggan.photo_url ? (
                        <Image
                          src={`${asset.pelanggan.photo_url}`}
                          alt="profile-pict"
                          width="40px"
                          height="40px"
                          style={{ borderRadius: "50%", marginRight: "10px" }}
                        />
                      ) : (
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            backgroundColor: "orange",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "10px",
                          }}>
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}>
                            {asset.pelanggan.name
                              ? asset.pelanggan.name.charAt(0)
                              : ""}
                          </span>
                        </div>
                      )}
                      <div>
                        <span className="fw-bold">{asset.pelanggan.name}</span>
                      </div>
                    </div>
                    <div>
                      <span className=" text-muted ">Alamat</span>
                      <br />
                      <p className="h6">{asset.pelanggan.alamat}</p>
                    </div>
                    <div>
                      <span className=" text-muted ">Kontak</span>
                      <br />
                      <p className="h6">{asset.pelanggan.phone}</p>
                    </div>
                    <div>
                      <span className=" text-muted ">Email</span>
                      <br />
                      <p className="h6">{asset.pelanggan.email}</p>
                    </div>
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <hr />
            <div>
              <small className="text-muted text-s">
                Created at{" "}
                {asset.createdAt
                  ? moment.utc(asset.createdAt).format("YYYY-MM-DD HH:mm:ss")
                  : "-"}
              </small>
              <br />
              <small className="text-muted text-s">
                Last updated{" "}
                {asset.updatedAt
                  ? moment.utc(asset.updatedAt).format("YYYY-MM-DD HH:mm:ss")
                  : "-"}
              </small>
            </div>
          </Container>
        ) : (
          <div className="text-center">
            Failed to fetch asset. Please try again later.
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default DetailAsset;
