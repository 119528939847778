import React, { useEffect, useState, useCallback, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCheckSquare,
  faDatabase,
  faFlag,
  faMapLocationDot,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import {
  Col,
  Row,
  Spinner,
  Card,
  Table,
  Image,
  Nav,
  Pagination,
  Form,
  InputGroup,
} from "@themesberg/react-bootstrap";
import Select from "react-select";
import { CounterWidget } from "../../components/Widgets";
import assetSvc from "../../services/assetSvc";
import moment from "moment";
import reportSvc from "../../services/reportSvc";
import userSvc from "../../services/userSvc";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  FeatureGroup,
} from "react-leaflet";

import useUserStore from "../../zustand/useUserStore";

import "../../assets/styles/leaflet.css";
import L from "leaflet";
import marker from "../../assets/img/marker-removebg-preview.png";
import { customStyles } from "../../utils/SelectUtils";
import customerSvc from "../../services/customerSvc";
import { getLoginData } from "../../services/authSvc";
import dataHoliday from "../../data/holiday_2024.json";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import useAuthStore from "../../zustand/useUserStore";
const customMarkerIcon = new L.Icon({
  iconUrl: marker,
  iconRetinaUrl: marker,
  iconSize: [32, 45],
  iconAnchor: [16, 45],
});

export default () => {
  const [assetTotal, setAssetTotal] = useState([]);
  const [assets, setAssets] = useState([]);
  const [reports, setReports] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoadingSummary, setIsLoadingSummary] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [totalTemuan, setTotalTemuan] = useState(null);
  const [assetSummary, setAssetSummary] = useState(0);
  const [totalRows, setTotalRows] = useState("");
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  moment.tz.setDefault("Asia/Bangkok");

  const MAPBOX_URL = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;
  const { user } = getLoginData();
  // const { getCurrentUser } = useUserStore();
  // const currentUser = getCurrentUser();
  // console.log({ user, currentUser });

  const renderPaginationItems = () => {
    const paginationItems = [];

    paginationItems.push(
      <Pagination.First
        key="first"
        onClick={() => handlePageChange(1)}
        disabled={activePage === 1}
      />
    );

    paginationItems.push(
      <Pagination.Prev
        key="prev"
        onClick={() => activePage > 1 && handlePageChange(activePage - 1)}
        disabled={activePage === 1}
      />
    );

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= activePage - 2 && i <= activePage + 2)
      ) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageChange(i)}
            active={i === activePage}
          >
            {i}
          </Pagination.Item>
        );
      } else if (
        (i === activePage - 3 && i > 2) ||
        (i === activePage + 3 && i < totalPages - 1)
      ) {
        paginationItems.push(<Pagination.Ellipsis key={i} />);
      }
    }

    paginationItems.push(
      <Pagination.Next
        key="next"
        onClick={() =>
          activePage < totalPages && handlePageChange(activePage + 1)
        }
        disabled={activePage === totalPages}
      />
    );

    paginationItems.push(
      <Pagination.Last
        key="last"
        onClick={() => handlePageChange(totalPages)}
        disabled={activePage === totalPages}
      />
    );

    return paginationItems;
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const newStartIndex = (pageNumber - 1) * itemsPerPage;
    const newEndIndex = newStartIndex + itemsPerPage;
    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setActivePage(1); // Reset active page to the first page
    const newStartIndex = 0;
    const newEndIndex = newStartIndex + newItemsPerPage;
    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  };

  // FOR LEAFLET MAPS
  const fetchAssets = async () => {
    const resp = await assetSvc.getAsset({
      params: {
        page: 0,
        limit: 1000,
        id_pelanggan: selectedCustomer ? selectedCustomer.value : "",
      },
    });
    if (resp.status === 200) {
      setAssets(resp.data);
      setAssetTotal(resp.data);
    }
  };

  const fetchCustomers = async () => {
    const resp = await customerSvc.getCustomer({
      params: {
        page: 0,
        limit: 10000,
      },
    });
    if (resp.status === 200) {
      setCustomers(resp.data.data);
    }
    return resp;
  };

  const fetchDataMemoized = useCallback(() => {
    fetchCustomers().then((result) => {
      setCustomers(result.data.data);
    });
  }, []);

  useEffect(() => {
    fetchDataMemoized();
  }, [fetchDataMemoized]);

  // get asset with filter active
  const fetchAssetSummary = async () => {
    try {
      setIsLoadingSummary(true);
      const resp = await reportSvc.getAssetSummaryReport({
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      });
      if (resp.status === 200) {
        setAssetSummary(resp.data);
      }
      setIsLoadingSummary(false);
    } catch (error) {}
  };

  const handleStartDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setStartDate(formattedDate);
    if (moment(formattedDate).isAfter(endDate)) {
      setEndDate(formattedDate);
    }
  };

  const handleEndDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setEndDate(formattedDate);
    if (moment(formattedDate).isBefore(startDate)) {
      setStartDate(formattedDate);
    }
  };

  // get total operators
  const fetchUsers = async () => {
    const resp = await userSvc.getUser({
      params: {
        page: 0,
        limit: 1,
        role: "operator",
      },
    });
    if (resp.status === 200) {
      setUsers(resp.data);
    }
  };

  const fetchReports = async () => {
    setIsLoadingTable(true);
    let totalTemuan = null;

    if (endDate) {
      totalTemuan = await reportSvc.getReports({
        params: {
          page: activePage - 1,
          limit: itemsPerPage,
          start_date: startDate,
          end_date: endDate,
          is_temuan: true,
        },
      });
    } else {
      totalTemuan = await reportSvc.getReports({
        params: {
          page: activePage - 1,
          limit: itemsPerPage,
          start_date: startDate,
          is_temuan: true,
        },
      });
    }

    if (totalTemuan.status === 200) {
      setTotalTemuan(totalTemuan.data);
      setReports(totalTemuan.data);
      setStartIndex((activePage - 1) * itemsPerPage);
      setEndIndex(activePage * itemsPerPage);
      setTotalPages(totalTemuan.data.totalPage);
      setTotalRows(totalTemuan.data.totalRows);
    }
    setIsLoadingTable(false);
  };

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const truncateAddress = (address) => {
    const maxLength = 40; // Set the maximum length for the address
    if (address.length <= maxLength) {
      return address;
    }
    return address.substring(0, maxLength) + "...";
  };

  useEffect(() => {
    fetchReports();
  }, [startDate, endDate, activePage, itemsPerPage]);

  useEffect(() => {
    fetchAssets();
  }, [selectedCustomer]);

  useEffect(() => {
    const fetchData = async () => {
      // await fetchCustomers();
      await fetchUsers();
      await fetchAssetSummary();
    };
    fetchData();
    console.log({ context });
    console.log({ userStore });
  }, []);

  const customPaddingStyle = {
    padding: "0.75rem 0.5rem",
  };

  const context = useContext(AppContext);
  const userStore = useAuthStore();

  const mapRef = useRef(null);
  const groupRef = useRef(null);

  // const fitBounds = useCallback(() => {
  //   setTimeout(() => {
  //     if (mapRef.current && groupRef.current) {
  //       const bounds = L.featureGroup(groupRef.current.getLayers()).getBounds();
  //       mapRef.current.leafletElement.fitBounds(bounds);
  //     }
  //   }, 20000);
  // }, [assets]); // Depend on assets so it triggers when markers change

  // useEffect(() => {
  //   fitBounds();
  // }, [fitBounds]);

  return (
    <>
      <Row className="justify-content-md-center mt-2">
        {/* <h1>{context?.user}</h1>
        <h1>{JSON.stringify(userStore.user)}</h1> */}
        {!isLoadingSummary ? (
          <>
            <Col xs={6} sm={6} xl={3} className="mb-4  ">
              <CounterWidget
                category="Total ATM"
                title={
                  assetTotal && assetTotal.totalRows ? assetTotal.totalRows : 0
                }
                period={moment().format("YYYY-MM-DD HH:mm:ss")}
                bgColor={"#c2ff85"}
                bgIcon={"#228B22"}
                icon={faDatabase}
              />
            </Col>

            <Col xs={6} sm={6} xl={3} className="mb-4">
              <CounterWidget
                category="Total Operator"
                title={users && users.totalRows ? users.totalRows : 0}
                icon={faUser}
                bgColor={"#e0e0ff"}
                bgIcon={"#2020fa"}
              />
            </Col>
            <Col xs={6} sm={6} xl={3} className="mb-4">
              <CounterWidget
                category="Total Temuan"
                title={
                  totalTemuan && totalTemuan.totalRows
                    ? totalTemuan.totalRows
                    : 0
                }
                icon={faFlag}
                bgColor={"#ffdbdb"}
                bgIcon={"#fc2121"}
              />
            </Col>
            <Col xs={6} sm={6} xl={3} className="mb-4">
              <a href="/asset-summary">
                <CounterWidget
                  category="Belum Dikerjakan"
                  title={assetSummary.total_belum_dikerjakan}
                  icon={faCheckSquare}
                  bgColor={"#ffeed4"}
                  bgIcon={"orange"}
                />
              </a>
            </Col>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            colSpan="5"
            style={{ height: "137px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </Row>

      <Row>
        <Col
          xl={12}
          // className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0"
        >
          <Card border="light" className="shadow-sm mb-4">
            <Card.Title className="text-xl-center d-flex align-items-center my-auto border-bottom border-light mb-2">
              <div
                className={`icon icon-shape icon-md`}
                // style={{ backgroundColor: "#fc2121" }}
              >
                <FontAwesomeIcon icon={faFlag} style={{ color: "#fc2121" }} />
              </div>

              <h5 className="m-0">Temuan Operator</h5>
            </Card.Title>
            <Card.Body>
              <Row className="align-items-center">
                <Form>
                  <div className="mb-4">
                    <Form.Group id="range">
                      <Row>
                        <Col>
                          <Form.Label>Tanggal dimulai</Form.Label>
                          <Datetime
                            timeFormat={false}
                            onChange={handleStartDateChange}
                            renderInput={(props, openCalendar) => (
                              <InputGroup className="me-2">
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </InputGroup.Text>
                                <Form.Control
                                  required
                                  type="text"
                                  value={startDate}
                                  placeholder="YYYY-MM-DD"
                                  onFocus={openCalendar}
                                  onChange={() => {}}
                                />
                              </InputGroup>
                            )}
                          />
                        </Col>
                        <Col>
                          <Form.Label>Tanggal berakhir</Form.Label>
                          <Datetime
                            timeFormat={false}
                            onChange={handleEndDateChange}
                            renderInput={(props, openCalendar) => (
                              <InputGroup className="me-2">
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </InputGroup.Text>
                                <Form.Control
                                  required
                                  type="text"
                                  value={endDate}
                                  placeholder="YYYY-MM-DD"
                                  onFocus={openCalendar}
                                  onChange={() => {}}
                                />
                              </InputGroup>
                            )}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </Form>
              </Row>
              <Table
                responsive
                className="table-centered table-nowrap rounded mt-1"
              >
                <thead className="thead-light">
                  <tr>
                    <th className="border-0" style={customPaddingStyle}>
                      Nama Operator
                    </th>
                    <th className="border-0" style={customPaddingStyle}>
                      ATM
                    </th>
                    <th className="border-0" style={customPaddingStyle}>
                      Lokasi
                    </th>
                    <th className="border-0" style={customPaddingStyle}>
                      Kunjungan
                    </th>
                    <th className="border-0" style={customPaddingStyle}>
                      Keterangan
                    </th>
                    <th className="border-0" style={customPaddingStyle}>
                      Tanggal
                    </th>
                    <th className="border-0" style={customPaddingStyle}>
                      Detail
                    </th>
                  </tr>
                </thead>
                {isLoadingTable ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center"
                      style={{ height: "300px" }}
                    >
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </td>
                  </tr>
                ) : reports.totalRows > 0 ? (
                  <tbody>
                    {reports.data.map((report) => (
                      <tr key={report.id_laporan}>
                        <td className="border-0" style={customPaddingStyle}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {report.user_photo_url ? (
                              <Image
                                src={report.user_photo_url}
                                alt="profile-pict"
                                width="40px"
                                height="40px"
                                style={{
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                                loading="lazy"
                              />
                            ) : (
                              <div
                                className="bg-primary"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                  backgroundColor: "orange",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {report.user_name.charAt(0)}
                                </span>
                              </div>
                            )}
                            <div>
                              <span className="fw-bold">
                                {report.user_name}
                              </span>
                              <br />
                              Operator
                            </div>
                          </div>
                        </td>

                        <td className="border-0" style={customPaddingStyle}>
                          <span className="fw-bolder">{report.asset_name}</span>
                          <br />
                          {report.asset_kode}
                        </td>
                        <td className="border-0" style={customPaddingStyle}>
                          <span className="fw-bolder">
                            {truncateAddress(report.asset_alamat)}
                          </span>
                          <br />
                          {report.pelanggan_name}
                        </td>
                        <td
                          className="border-0 text-center"
                          style={customPaddingStyle}
                        >
                          <span className="fw-bolder">{report.kunjungan}</span>
                        </td>
                        <td className="border-0 " style={customPaddingStyle}>
                          <span className="fw-bolder">
                            {report.temuan.keterangan}
                          </span>
                        </td>
                        <td
                          className="fw-bold border-0"
                          style={customPaddingStyle}
                        >
                          <span>
                            {moment.utc(report.date_done).format("YYYY-MM-DD")}
                          </span>
                          <br />
                          {moment.utc(report.date_done).format("HH:mm:ss")}
                        </td>
                        <td
                          className="fw-bold border-0"
                          style={customPaddingStyle}
                        >
                          <a
                            href={`/report-detail/${report.id_laporan}`}
                            rel="noopener noreferrer"
                            className="fw-bolder"
                          >
                            Lihat
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      className="text-center"
                      style={{ height: "200px" }}
                    >
                      <span className="text-muted">Belum ada temuan</span>
                    </td>
                  </tr>
                )}
              </Table>
            </Card.Body>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <small className="me-2">Limit:</small>
                <Form.Select
                  className="me-3"
                  style={{ width: "70px" }}
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
                <small className="fw-bold">
                  Menampilkan {startIndex + 1} -{" "}
                  {Math.min(startIndex + itemsPerPage, totalRows)} dari{" "}
                  {totalRows} laporan temuan
                </small>
              </div>

              <div className="d-flex align-items-center">
                <Nav className="mx-2">
                  <Pagination className="mb-2 mb-lg-0">
                    {renderPaginationItems()}
                  </Pagination>
                </Nav>
              </div>
            </Card.Footer>
          </Card>

          <Card border="light" className="shadow-sm">
            <Card.Title className="text-xl-center d-flex align-items-center my-auto border-bottom border-light mb-2">
              <div className={`icon icon-shape icon-md`}>
                <FontAwesomeIcon
                  icon={faMapLocationDot}
                  style={{ color: "#228B22" }}
                />
              </div>

              <h5 className="m-0">Persebaran ATM</h5>
            </Card.Title>
            <Card.Body>
              <div
                className="d-flex justify-content-between mb-3"
                style={{ position: "relative", zIndex: 10 }}
              >
                <InputGroup className="me-1">
                  <Select
                    id="state"
                    placeholder="Pilih Kantor Cabang"
                    styles={customStyles}
                    value={selectedCustomer}
                    isClearable
                    onChange={handleSelectCustomer}
                    options={[
                      ...customers.map((user) => ({
                        value: user.id_pelanggan,
                        label: user.name,
                        key: user.id_user,
                      })),
                    ]}
                  />
                </InputGroup>
              </div>

              <MapContainer
                center={[-6.1259391, 106.5262299]}
                zoom={9}
                scrollWheelZoom={true}
                style={{
                  height: "70vh",
                  width: "100%",
                  position: "relative",
                  zIndex: 1,
                }}
                ref={mapRef}
              >
                {/* <MapClickHandler /> */}
                <TileLayer
                  // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  url={MAPBOX_URL}
                />
                <FeatureGroup ref={groupRef}>
                  {assets?.data &&
                    assets?.data?.map((element) => {
                      // Check if latitude and longitude are valid
                      if (element.latitude && element.longitude) {
                        return (
                          <Marker
                            position={[element.latitude, element.longitude]}
                            icon={customMarkerIcon}
                          >
                            <Popup>
                              <>
                                {`${element?.name} - ${element?.kode}`} <br />
                                {element?.pelanggan?.name}
                              </>
                            </Popup>
                          </Marker>
                        );
                      } else {
                        return null; // Skip rendering the Marker if latitude or longitude is missing
                      }
                    })}
                </FeatureGroup>
              </MapContainer>
            </Card.Body>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between"></Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};
